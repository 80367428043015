import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";

// markup
export default function ReservedPage() {
  return (
    <Layout>
      <Helmet title="VIP Dine - Privacy Policy" />
      <section className="section hero reserved-hero sub-page">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>
                <span className="product-name yellow">VIP Dine</span> - Privacy
                Policy
              </h1>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3>About this policy</h3>
              <p>
                VIP Dine Ltd. is committed to protecting your privacy and
                ensuring that your personal information is handled in a safe and
                responsible way. This privacy policy explains how we use any
                personal information we collect about you when you use this
                website or book or visit VIP Dine Ltd, Reserved. webpages
              </p>
              <p>
                This policy outlines how we aim to achieve this and includes the
                information collected when:
              </p>
              <ul>
                <li>
                  you use our domains –
                  <ul>
                    <li>Product website: https://www.vip-dine.com/</li>
                    <li>
                      Reservations widget used by subscribing restaurants:
                      https://invictuswidget.itisreserved.com/
                    </li>
                    <li>
                      Admininstration portal for subscribers:
                      https://admin.itisreserved.com/
                    </li>
                  </ul>
                </li>
                <li>you make enquiries on our website.</li>
                <li>someone is interested in working with us.</li>
                <li>
                  all other activities performed on online produts of VIP Dine
                  Ltd.
                </li>
              </ul>
              <h3>Definition of Personal Data</h3>
              <p>
                Personal Data means any data that relates to an identifiable
                person who can be directly/indirectly identified from that data.
                In this case, it means personal data that you give to us via our
                site.
              </p>
              <p>
                By providing your personal data, you agree that we can use your
                personal data in accordance with this policy.
              </p>
              <p>
                Ensure you understand this policy in its entirety and take your
                time to read it.
              </p>
              <p>This explains:</p>
              <ul>
                <li>What information do we collect about you?</li>
                <li>How will we use the information about you?</li>
                <li>Your rights</li>
                <li>Keeping your information</li>
                <li>How we secure your information</li>
                <li>Marketing</li>
                <li>Cookies</li>
                <li>Changes to our privacy policy</li>
                <li>How to contact us</li>
                <li>What information do we collect about you?</li>
              </ul>
              <h3>Who are we?</h3>
              <p>
                Our registered address is: 4 Beeches Way, Horsham. UK. RH12 0AD.
              </p>
              <p>
                The head office address is: 4 Beeches Way, Horsham. UK. RH12
                0AD.
              </p>
              <h3>How do we collect information from you?</h3>
              <p>We collect information from you:</p>
              <p>when you make a booking.</p>
              <p>when you visit a restaurant (preferences, allergies etc.).</p>
              <p>make an enquiry.</p>
              <p>when you sign up to marketing emails.</p>
              <p>What type of information is collected from you?</p>
              <p>
                You may be asked to submit personal information about yourself
                when you make a booking. We will collect this information so we
                can fulfil your booking request and you may dine at our venue.
              </p>
              <p>When you make a booking:</p>
              <p>
                VIP Dine Ltd., 4 Beeches Way, Horsham. UK. RH12 0AD collects
                information such as:
              </p>
              <ul>
                <li>title</li>
                <li>name</li>
                <li>
                  e-mail address (used for booking confirmation and post-dining
                  feedback emails)
                </li>
                <li>home or work address</li>
                <li>telephone number</li>
                <li>company name</li>
                <li>dietary requests</li>
                <li>marketing preferences (whether you opt-in or opt-out)</li>
                <li>To guarantee bookings and take payment</li>
                <li>marketing responses (where applicable)</li>
                <li>survey responses</li>
                <li>current and past restaurant reservation details</li>
              </ul>
              <p>When you access our sites:</p>
              <p>
                There is “Device Information” about your computer hardware and
                software that is automatically collected by VIP Dine Ltd. 4
                Beeches Way, Horsham. UK. RH12 0AD. This information can
                include:
              </p>
              <ul>
                <li>device type (e.g. mobile, computer, laptop, tablet)</li>
                <li>cookies</li>
                <li>operating system</li>
                <li>IP address</li>
                <li>browser type</li>
                <li>browser information (e.g., type, language, and history)</li>
                <li>domain names</li>
                <li>access times</li>
                <li>settings</li>
                <li>referring website addresses</li>
                <li>
                  other data about your device to provide the services as
                  otherwise described in this policy.
                </li>
              </ul>
              <h3>Location information:</h3>
              <p>
                If you use our website, we may receive your generic location
                (such as city or neighbourhood).
              </p>
              <h3>Careers:</h3>
              <p>
                You may submit your CV if you’re interested in working for us to
                info@vip-dining.co.uk –  This information may include:
              </p>
              <ul>
                <li>personal details</li>
                <li>employment details</li>
                <li>education</li>
                <li>salary history</li>
                <li>other relevant details</li>
              </ul>
              <p>
                We will use this information to assess your application. We may
                also keep it in our records for future reference. Please get in
                contact if you would no longer like us to hold your records at
                <a href="mailto:info@vip-dining.com">info@vip-dining.co.uk</a>
              </p>
              <p>In addition:</p>
              <p>
                At various times, we will be obliged to ask you, as an VIP Dine
                Ltd. customer, for information about you and/or members of your
                family, such as:
              </p>
              <ul>
                <li>
                  Contact details (for example: last name, first name, telephone
                  number, email)
                </li>
                <li>
                  Personal information (for example: date of birth, nationality)
                </li>
                <li>
                  Information relating to your children (for example: first
                  name, date of birth, age)
                </li>
                <li>
                  Your credit card number (for transaction and reservation
                  purposes)
                </li>
                <li>
                  Your membership number if you are a member of our loyalty
                  program
                </li>
                <li>
                  Your arrival and departure dates for your stay with our
                  partners or local hotels.
                </li>
                <li>
                  Your preferences and interests (for example: preferred dining
                  area, type of food, shisha flavour, sports, cultural
                  interests)
                </li>
                <li>
                  Your questions/comments, during or following a visit at our
                  Restaurant, Bar or Lounge.
                </li>
                <li>
                  The information collected in relation to persons under 18
                  years of age is limited to their name, nationality and date of
                  birth, which can only be supplied to us by an adult.
                </li>
                <li>To manage invoicing and payment records</li>
              </ul>
              <h3>How will we use the information about you?</h3>
              <p>
                We use your information in a number of different ways; primarily
                to fulfil a contract and also provide excellent service to our
                customers— what we do depends on the information. The section
                below sets this out in detail, showing what we use the
                information we collect for.
              </p>
              <h3>How is your information used?</h3>
              <p>
                Our use of your personal data will always have a lawful basis,
                either because it is necessary to complete a booking, because
                you have consented to our use of your personal data (e.g. by
                subscribing to emails), or because it is in our legitimate
                interests.
              </p>
              <p>
                We require the information outlined in the previous section to
                understand your needs and provide you with a better service, and
                in particular for the following reasons:
              </p>
              <ul>
                <li>Internal record keeping.</li>
                <li>
                  Send you service emails (booking confirmation and post-dining
                  feedback).
                </li>
                <li>Improve our products and services.</li>
                <li>
                  Send marketing communications if you have opted in to receive
                  them.
                </li>
                <li>
                  We may use the information to customise the website according
                  to your interests.
                </li>
                <li>Who has access to your information?</li>
                <li>
                  We will not sell, distribute, or lease your personal
                  information to third parties. Any personal information we
                  request from you will be safeguarded under current
                  legislation.
                </li>
                <li>
                  We will only share your information with companies if
                  necessary to deliver services on our behalf. For example
                  service providers (e.g. DesignMyNight.com or bookatable.com or
                  itsreserved.com for the provision of online bookings),
                  third-party payment processors, and other third parties to
                  provide our Sites and fulfil your requests, and as otherwise
                  consented to by you or as permitted by applicable law.
                </li>
              </ul>
              <p>
                Third parties (including DesignMyNight.com; its reserved.com)
                whose content appears on our Site may use third-party Cookies,
                as detailed below. Please refer to ‘Use of Cookies’ for more
                information on controlling Cookies. Please note that we do not
                control the activities of such third parties, nor the data they
                collect and use and advise you to check the privacy policies of
                any such third parties.
              </p>
              <p>
                You may choose to restrict the collection or use of your
                personal information at any point.  Please refer to the Your
                Choices section of this Privacy Policy for details.
              </p>
              <h3>How and where do we store data?</h3>
              <p>
                We only keep your personal data for as long as we need to (12
                months) in order to use it as described in this privacy policy,
                and/or for as long as we have your permission to keep it.
              </p>
              <p>
                For reservations taken through DesignMyNight software, your data
                will only be stored in the UK.
              </p>
              <h3>Profiling</h3>
              <p>
                We may analyse your personal information to create a profile of
                your interests and preferences so that we can contact you with
                information relevant to you. We may make use of additional
                information about you when it is available from external sources
                to help us do this effectively.
              </p>
              <p>Preferences & Interests</p>
              <p>
                To enhance and personalise a guest’s stay with us and improve
                the services we offer
              </p>
              <p>Comments & Feedback</p>
              <p>
                To collect information to improve our services and monitor
                customer experience.
              </p>
              <h3>
                <strong>Your choices</strong>
              </h3>
              <p>You have rights relating to your personal information:</p>
              <p>
                The right to be informed about how your personal information is
                being used (like this notice)
              </p>
              <p>
                The right to access the personal information we hold about you
              </p>
              <p>
                The right to request the correction of inaccurate personal
                information we hold about you
              </p>
              <p>
                The right to request that we delete your data, or stop
                processing it or collecting it, in some circumstances
              </p>
              <p>
                The right to stop direct marketing messages, and to withdraw
                consent for other consent-based processing at any time
              </p>
              <p>
                The right to request that we transfer or export elements of your
                data either to you or another service provider
              </p>
              <p>
                The right to complain to your data protection regulator — in the
                UK, the Information Commissioner’s Office
              </p>
              <p>
                If you want to exercise your rights, have a complaint, or just
                have questions, please contact us. Details are in the Contact Us
                section at the end of this document.
              </p>
              <h3>Keeping your information</h3>
              <p>
                We’ll hold on to your information for as long as you have a
                booking with us, and for as long as is necessary to provide
                support-related reporting.
              </p>
              <p>
                We’ll also hold on to your information if reasonably necessary
                or required to meet legal or regulatory requirements, resolve
                disputes, prevent fraud and abuse, or enforce our terms and
                conditions. We may also keep hold of some of your information as
                required, even if it is no longer needed to provide the services
                to you.
              </p>
              <p>
                We will not contact you for marketing purposes by email, phone
                or text message unless you have given your prior consent. We
                will not pass your details to any third parties for marketing
                purposes unless you have expressly permitted us to. Furthermore,
                you can change your marketing preferences at any time by
                contacting us by email at info@vip-dining.co.uk.
              </p>
              <p>
                You have a right to request a copy of the personal information
                that VIP Dine Ltd. holds about you and have any inaccuracies
                corrected. Any such requests should be made to this email
                address: info@vip-dining.co.uk.
              </p>
              <p>
                You have the right to withdraw your consent to us using your
                personal data at any time, and to request that we delete it. We
                do not keep your personal data for any longer than is necessary
                in light of the reason(s) for which it was first collected. Data
                will, therefore, be retained for the following periods (12
                months):
              </p>
              <h3>Security</h3>
              <p>
                VIP Dine Ltd. takes data security seriously, and we use
                appropriate technologies and procedures to protect personal
                information and safeguard and secure data collected through our
                Site.
              </p>
              <p>
                We have measures in place to protect against accidental loss and
                unauthorised access, use, destruction, or disclosure of data
              </p>
              <p>
                We place appropriate restrictions on access to personal
                information
              </p>
              <p>
                We implement appropriate measures and controls, including
                monitoring and physical measures, to store and transfer data
                securely.
              </p>
              <h3>Marketing</h3>
              <p>
                We would like to send you information about products and
                services of ours and other companies in our Group which may be
                of interest to you. If you have consented to receive marketing,
                you can still opt out at a later date.
              </p>
              <p>
                You have the right, at any time, to stop us from contacting you
                for marketing purposes or giving your information to other
                members of VIP Dine Ltd.s. If you no longer wish to be contacted
                for marketing purposes, please click here.
              </p>
              <p>Our marketing is managed by a third party.</p>
              <h3>Use of ‘cookies’</h3>
              <p>
                Like many other websites, we use cookies. We use them to help
                you personalise your online experience.
              </p>
              <p>
                There are four main types of cookies – here’s how and why we use
                them.
              </p>
              <p>
                (1) Site functionality cookies – these cookies allow you to
                navigate the site and use our features.
              </p>
              <p>
                (2) Site analytics cookies – these cookies allow us to measure
                and analyse how our customers use the site, to improve both its
                functionality and your shopping experience.
              </p>
              <p>
                (3) Customer preference cookies – when you are browsing, these
                cookies will remember your preferences (like your language or
                location), so we can make your experience as seamless as
                possible, and more personal to you.
              </p>
              <p>
                (4) Targeting or advertising cookies – these cookies are used to
                deliver ads relevant to you. They also limit the number of times
                that you see an ad and help us measure the effectiveness of our
                marketing campaigns.
              </p>
              <p>
                By using our site, you agree to us placing these sorts of
                cookies on your device and accessing them when you visit the
                site in the future. If you want to delete any cookies that are
                already on your computer, the “help” section in your browser
                should provide instructions on how to locate the file or
                directory that stores cookies. Further information about cookies
                can be found at
                https://ico.org.uk/for-organisations/guide-to-pecr/cookies-and-similar-technologies/
                . Please note that by deleting or disabling future cookies, your
                user experience may be affected, and you might not be able to
                take advantage of certain functions of our site.
              </p>
              <p>
                A cookie is a text file that is placed on your hard disk by a
                web page server which allows the website to recognise you when
                you visit. Cookies only collect data about browsing actions and
                patterns, and do not identify you as an individual.
              </p>
              <h3>We use cookies for the following purposes:</h3>
              <p>
                Authentication, personalisation and security: cookies help us
                verify your account and device and determine when you log in, so
                we can make it easier for you to access the services and provide
                the appropriate experiences and features. We also use cookies to
                help prevent fraudulent use of login credentials.
              </p>
              <p>
                Performance and analytics: cookies help us analyse how the
                services are being accessed and used, and enable us to track the
                performance of the services. For example, we use cookies to
                determine if you viewed a page or opened an email. This helps us
                provide you with information that you find interesting. We also
                use cookies to provide insights regarding your End Users and
                your sites’ performance, such as page views, conversion rates,
                device information, visitor IP addresses, and referral sites.
              </p>
              <p>
                Third Parties: Third Party services may use cookies to help you
                sign into their services from our services. We also may use
                third-party cookies, such as Google Analytics, to assist with
                analysing performance. Any third party cookie usage is governed
                by the privacy policy of the third party placing the cookie.
              </p>
              <p>
                Opting Out: You can set your browser to not accept cookies, but
                this may limit your ability to use the services.
              </p>
              <p>
                Our Site may contain links to other websites. Please note that
                we have no control over how your data is collected, stored, or
                used by other websites and we advise you to check the privacy
                policies of any such websites before providing any data to them.
              </p>
              <h3>Restaurant groups</h3>
              <p>
                We may share your information (such as meal or seating
                preferences and special occasions) with other restaurants in the
                same restaurant group. This is to enhance the hospitality
                experience that we VIP Dine Ltd.
              </p>
              <p>
                In addition to providing, you with more customised service, we
                may, as permitted by applicable law, share your information with
                our restaurant affiliates to support operations, such as to
                perform analytics, tailor marketing to you, support a loyalty
                program that you have chosen to participate in, and improve
                services.
              </p>
              <p>
                For more information, please feel free to contact us at:
                (info@vip-dining.co.uk)
              </p>
              <h3>Changes to this statement</h3>
              <p>
                VIP Dine Ltd. will occasionally update this Privacy Policy to
                reflect company and customer feedback. VIP Dine Ltd., encourages
                you to periodically review this statement to be informed of how
                VIP Dine Ltd. is protecting your information. This policy was
                last updated in (March 2021)
              </p>
              <h3>Contact Information</h3>
              <p>
                VIP Dine Ltd. welcomes your comments regarding this Privacy
                Policy. If you believe that VIP Dine Ltd. has not adhered to
                this Privacy Policy, please contact VIP Dine Ltd. at
                info@vip-dining.co.uk. We will aim to use commercially
                reasonable efforts to promptly determine and remedy the problem.
              </p>
              <p>Or if you’d like to, you can write to us at:</p>
              <p>VIP Dine Ltd. – Data Protection</p>
              <p>4 Beeches Way</p>
              <p>Horsham</p>
              <p>RH12 0AD</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
